import React, { useEffect, useState } from "react";
import { Text } from "@sqymagma/elements";
import { StepWrapper } from "../style";
import { WRAPPED_STEPS } from "../constants";

import thanks from "@gifs/thanks.gif";
import { GifEnd } from "./style";

interface FarewellProps {
  active: boolean;
  year: number;
}

const Farewell = ({ active, year }: FarewellProps) => {
  const config = WRAPPED_STEPS.find(({ step }) => step === "Farewell");
  const durations = config?.children;

  const [activeItem, setActiveItem] = useState(0);

  const nextItem = (active: number) => {
    if (!durations || active >= durations.length) {
      return;
    }

    setActiveItem(active);

    setTimeout(() => {
      nextItem(active + 1);
    }, durations[active] * 1000);
  };

  useEffect(() => {
    if (active && durations) {
      setTimeout(() => {
        nextItem(activeItem + 1);
      }, durations[activeItem] * 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <StepWrapper
      $bg="#FF4C96"
      $deg1="#6F7BF7"
      $deg2="#BA73D9"
      $rotate="109.222deg"
      style={{
        opacity: active ? 1 : 0,
      }}
    >
      {/* 1 */}
      {activeItem === 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Text
            color="#fff"
            width="100%"
            style={{
              fontFamily: "'Abril Fatface', serif",
              fontSize: "46px",
              lineHeight: "97px",
              textAlign: "center",
            }}
          >
            ¡Gracias!
          </Text>
          <GifEnd $image={thanks} />
        </div>
      )}

      {/* 2 */}
      {activeItem === 1 && (
        <div
          style={{
            paddingBlock: 0,
            overflow: "hidden",
            textAlign: "center",
          }}
        >
          {new Array(20).fill("#somossecuoyas").map((value, index) => (
            <Text
              key={index}
              color={`rgba(255, 255, 255, ${1 - 0.06 * index})`}
              display="block"
              style={{
                fontFamily: "'Abril Fatface', serif",
                fontSize: "44px",
                lineHeight: "normal",
                letterSpacing: "0.88px",
                opacity: index === 0 ? 1 : 0,
                animation: `fadeIn 0s ${index * 0.25}s forwards`,
                whiteSpace: "nowrap",
              }}
            >
              {value}
            </Text>
          ))}
        </div>
      )}

      {/* 3 */}
      {activeItem === 2 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            color="#fff"
            width="70%"
            mt="32px"
            style={{
              fontFamily: "'Abril Fatface', serif",
              fontSize: "168px",
              lineHeight: "156px",
              wordBreak: "break-word",
              opacity: 0,
              animation: `fadeIn 500ms 1s forwards`,
              textAlign: "center",
            }}
          >
            {year + 1}
          </Text>

          <Text
            color="#fff"
            style={{
              fontFamily: "'Noto Serif', serif",
              fontSize: "25px",
              lineHeight: "97px",
              opacity: 0,
              animation: `fadeIn 500ms 2s forwards`,
            }}
          >
            ¡Allá vamos!
          </Text>
        </div>
      )}
    </StepWrapper>
  );
};

export default Farewell;
