/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactGiphySearchbox from "react-giphy-searchbox";
import JoditEditor, { IJoditEditorProps, Jodit } from "jodit-react";

import { Gif } from "@types";
import { useToast } from "@contexts";
import { useHandleClickOutside, useKeyboard, useModal } from "@hooks";
import {
  getBlobFromFile,
  getYouTubeVideoId,
  uuid,
  getSpotifyResourceId,
} from "@helpers";
import firebase from "@database";
import EmojiSelector, { EmojiClickData } from "@elements/EmojiSelector";

import * as S from "./style";
import { getIcon } from "./icons";
import { LinkModal, YoutubeModal, SpotifyModal } from "./modals";

const maxImageUploadSize = 10; // size in megas

const { REACT_APP_GIPHY_API_KEY: giphyApiKey } = process.env;

interface EditorProps extends IJoditEditorProps {
  onChange: (content: string) => void;
  placeholder?: string;
  buttons?: Array<"youtube" | "spotify">;
}

const EditorElement = (props: EditorProps) => {
  const { onChange, placeholder, buttons, value = "" } = props;

  const { addToast } = useToast();
  const storage = firebase.storage();

  const editorRef = useRef<Jodit | null>(null); // Jodit editor instance

  const [openEmojis, setOpenEmojis] = useState(false);

  const gifSearchRef = useRef<HTMLDivElement>(null);
  const [openGifSearch, setOpenGifSearch] = useState(false);

  const { isOpen: isYoutubeOpen, toggleModal: toggleYoutubeModal } =
    useModal(false);
  const { isOpen: isSpotifyOpen, toggleModal: toggleSpotifyModal } =
    useModal(false);
  const { isOpen: isLinkOpen, toggleModal: toggleLinkModal } = useModal(false);

  const handleClickOutsideGifSearch = (e: MouseEvent) => {
    if (gifSearchRef.current && gifSearchRef.current.contains(e.target as Node))
      return;
    setOpenGifSearch(false);
  };
  useHandleClickOutside(openGifSearch, handleClickOutsideGifSearch);

  const closeModals = useKeyboard(["escape"]);

  useEffect(() => {
    if (closeModals) {
      setOpenGifSearch(false);
      setOpenEmojis(false);
      isYoutubeOpen && toggleYoutubeModal();
      isSpotifyOpen && toggleSpotifyModal();
      isLinkOpen && toggleLinkModal();
    }
  }, [
    closeModals,
    isLinkOpen,
    isYoutubeOpen,
    toggleLinkModal,
    toggleYoutubeModal,
    toggleSpotifyModal,
    isSpotifyOpen,
  ]);

  useEffect(() => {
    if (!value.length && editorRef.current) {
      editorRef.current.value = "";
    }
  }, [value]);

  const insertEmoji = ({ emoji }: EmojiClickData) => {
    editorRef?.current?.selection.insertHTML(emoji);
    setOpenEmojis(false);
  };

  const insertGif = (item: Gif) => {
    const src = item.images.original.url;
    const html = `<img src="${src}" alt="gif" style="max-width: 100%; height: auto; border-radius: 10px;" />`;
    editorRef.current?.selection.insertHTML(html);
    setOpenGifSearch(false);
  };

  const insertImage = useCallback((url: string) => {
    if (url) {
      editorRef?.current?.selection.insertImage(url, {
        maxWidth: "100%",
        height: "auto",
        borderRadius: "10px",
      });
    }
  }, []);

  const insertLink = useCallback((url: string, text: string) => {
    const validUrl = url.startsWith("http") ? url : `https://${url}`;
    const link = `<a href="${validUrl}" target="_blank">${text || url}</a>`;
    editorRef?.current?.selection.insertHTML(link);
  }, []);

  const insertYoutubeVideo = useCallback((url: string) => {
    const id = getYouTubeVideoId(url);
    if (id) {
      const html = `<p><iframe title="video" width="309" height="174" src="https://www.youtube.com/embed/${id}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style="max-width: 100%; border-radius: 10px; overflow: hidden;"></iframe></p>`;
      editorRef.current?.selection.insertHTML(html);
    }
  }, []);

  const insertSpotifyIframe = useCallback((url: string) => {
    const [type, id] = getSpotifyResourceId(url);
    const html = `<p><iframe style="border-radius:12px" src="https://open.spotify.com/embed/${type}/${id}?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe></p>`;
    editorRef.current?.selection.insertHTML(html);
  }, []);

  const uploadImage = useCallback(
    async (file: any) => {
      if (!file) {
        addToast("No se ha seleccionado ningún archivo");
        return "";
      }
      const isFile = typeof file.name === "string";
      const name = isFile ? file.name : file.filename();
      const image = storage.ref(`images/${uuid()}_${name}`);
      const blob = isFile ? await getBlobFromFile(file) : file.blob();
      if (blob.size > maxImageUploadSize * 1000000) {
        addToast(`La imagen no puede exceder los ${maxImageUploadSize} MB`);
        return "";
      }
      await image.put(blob);
      const url = await image.getDownloadURL();
      return url;
    },
    [addToast, storage]
  );

  const youtubeBtn = useCallback(() => {
    return {
      name: "youtube",
      tooltip: "Vídeo de YouTube",
      icon: getIcon("youtube"),
      exec: async () => {
        // Check if there is a YouTube link in the clipboard and add the video automatically
        try {
          const clipboard = await navigator.clipboard.readText();
          if (
            clipboard.startsWith("https://www.youtube.com/watch?v=") ||
            clipboard.startsWith("https://youtu.be/")
          ) {
            insertYoutubeVideo(clipboard);
            return;
          }
        } catch (error) {
          console.error(error); // The user does not allow reading the clipboard
        }
        // If there is not a link in the clipboard, open modal to paste one
        toggleYoutubeModal();
      },
    };
  }, [insertYoutubeVideo, toggleYoutubeModal]);

  const spotifyBtn = useCallback(() => {
    return {
      name: "spotify",
      tooltip: "Spotify",
      icon: getIcon("spotify"),
      exec: async () => {
        // Check if there is a Spotify link in the clipboard and add the iframe automatically
        try {
          const clipboard = await navigator.clipboard.readText();
          if (clipboard.startsWith("https://open.spotify.com/")) {
            insertSpotifyIframe(clipboard);
            return;
          }
        } catch (error) {
          console.error(error); // The user does not allow reading the clipboard
        }
        // If there is not a link in the clipboard, open modal to paste one
        toggleSpotifyModal();
      },
    };
  }, [insertSpotifyIframe, toggleSpotifyModal]);

  const mediaButtons = useMemo<any>(
    () => [
      {
        name: "Emojis",
        tooltip: "Emojis",
        icon: getIcon("smile"),
        exec: () => setOpenEmojis(true),
      },
      {
        name: "gifs",
        tooltip: "Gifs",
        icon: getIcon("gif"),
        exec: () => setOpenGifSearch(true),
      },
      {
        name: "addImage",
        tooltip: "Subir imagen",
        icon: getIcon("image"),
        exec: () => {
          const input = document.createElement("input");
          input.type = "file";
          input.accept = "image/*";
          input.onchange = async () => {
            if (input.files?.length) {
              const url = await uploadImage(input.files[0]);
              insertImage(url);
            }
          };
          input.click();
        },
      },
    ],
    [insertImage, uploadImage]
  );

  buttons?.includes("youtube") && mediaButtons.push(youtubeBtn());
  buttons?.includes("spotify") && mediaButtons.push(spotifyBtn());

  const toolbarButtons = useMemo(
    () => [
      ...mediaButtons,
      "|",
      {
        name: "text",
        tooltip: "Formato",
        icon: getIcon("text"),
        list: {
          bold: { icon: getIcon("bold") },
          italic: { icon: getIcon("italic") },
          underline: { icon: getIcon("underline") },
          strikethrough: { icon: getIcon("strikethrough") },
        },
      },
      "|",
      {
        name: "addLink",
        tooltip: "Añadir enlace",
        icon: getIcon("link"),
        exec: toggleLinkModal,
      },
    ],
    [mediaButtons, toggleLinkModal]
  );

  const config = useMemo<IJoditEditorProps["config"]>(
    () => ({
      readonly: false,
      placeholder: placeholder || "Escribe aquí tu mensaje.",
      language: "es",
      statusbar: false,
      spellcheck: true,
      buttons: toolbarButtons,
      buttonsXS: toolbarButtons,
      buttonsSM: toolbarButtons,
      buttonsMD: toolbarButtons,
      editorClassName: "jodit__editor",
      className: "jodit__container",
      maxHeight: 500,
      minHeight: 90,
      toolbarButtonSize: "small",
      addNewLine: false,
      events: { afterInit: (editor: Jodit) => (editorRef.current = editor) },
      link: {
        noFollowCheckbox: false,
        openInNewTabCheckbox: false,
        modeClassName: null,
      },
    }),
    [placeholder, toolbarButtons]
  );

  const editor = useMemo(
    () => <JoditEditor value={value} config={config} onChange={onChange} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <S.JoditGlobalStyles />

      <S.EditorWrapper>
        {editor}

        {openEmojis && (
          <EmojiSelector
            action={insertEmoji}
            isOpen={openEmojis}
            close={() => setOpenEmojis(false)}
          />
        )}

        {openGifSearch && (
          <S.GifSearch ref={gifSearchRef}>
            <ReactGiphySearchbox
              apiKey={giphyApiKey}
              onSelect={insertGif}
              poweredByGiphy={false}
              searchPlaceholder="Buscar gifs"
              autoFocus
              listItemClassName="react-giphy-searchbox-list-item"
              messageError="¡Oops! Algo ha fallado. Por favor, inténtalo de nuevo."
              messageLoading="Cargando..."
              messageNoMatches="No se ha encontrado nada."
            />
          </S.GifSearch>
        )}
      </S.EditorWrapper>

      <YoutubeModal
        isOpen={isYoutubeOpen}
        toggleModal={toggleYoutubeModal}
        action={insertYoutubeVideo}
      />

      <SpotifyModal
        isOpen={isSpotifyOpen}
        toggleModal={toggleSpotifyModal}
        action={insertSpotifyIframe}
      />

      <LinkModal
        isOpen={isLinkOpen}
        toggleModal={toggleLinkModal}
        action={insertLink}
      />
    </>
  );
};

export default EditorElement;
