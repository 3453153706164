import React, { useEffect, useState } from "react";
import { Text, Flex } from "@sqymagma/elements";
import { StepWrapper } from "../style";
import { WRAPPED_STEPS } from "../constants";

import inicio from "@gifs/inicio.gif";
import { GifWelcome } from "./style";

interface WelcomeProps {
  active: boolean;
  year: number;
}

const Welcome = ({ active, year }: WelcomeProps) => {
  const config = WRAPPED_STEPS.find(({ step }) => step === "Welcome");
  const durations = config?.children;

  const [activeItem, setActiveItem] = useState(0);

  const nextItem = (active: number) => {
    if (!durations || active >= durations.length) {
      return;
    }

    setActiveItem(active);

    setTimeout(() => {
      nextItem(active + 1);
    }, durations[active] * 1000);
  };

  useEffect(() => {
    if (active && durations) {
      setTimeout(() => {
        nextItem(activeItem + 1);
      }, durations[activeItem] * 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <StepWrapper
      $bg="#543A80"
      $deg1="rgba(207, 175, 215, 0.80)"
      $deg2="rgba(150, 68, 255, 0.80)"
      $rotate="56.676deg"
      style={{
        opacity: active ? 1 : 0,
      }}
    >
      {/* 1 */}
      {activeItem === 0 && (
        <div
          style={{
            paddingBlock: 0,
            overflow: "hidden",
          }}
        >
          {new Array(20).fill("Wrap").map((value, index) => (
            <Text
              key={index}
              color="#77EFE1"
              display="block"
              mt={index === 0 ? "-24px" : 0}
              style={{
                fontFamily: "'Abril Fatface', serif",
                fontSize: "128px",
                lineHeight: "128px",
                letterSpacing: "2.56px",
                opacity: index === 0 ? 1 : 0,
                animation: `fadeIn 0s ${index * 0.25}s forwards, fadeOut 0s ${
                  index * 0.25 + 2
                }s forwards`,
              }}
            >
              {value}
            </Text>
          ))}
        </div>
      )}

      {/* 2 */}
      {activeItem === 1 && (
        <div style={{ overflowY: "hidden" }}>
          <Flex flexDirection="column" justifyContent="center" height="100%">
            <Flex flexDirection="column">
              {["Se", "cuo", "yas"].map((value) => (
                <Text
                  key={value}
                  color="#77EFE1"
                  display="block"
                  style={{
                    fontFamily: "'Abril Fatface', serif",
                    fontSize: "138px",
                    lineHeight: "100px",
                    letterSpacing: "3.36px",
                  }}
                >
                  {value}
                </Text>
              ))}
            </Flex>

            {activeItem === 1 && (
              <Text
                color="#fff"
                display="block"
                width="60%"
                ml="auto"
                style={{
                  fontFamily: "'Abril Fatface', serif",
                  fontSize: "138px",
                  lineHeight: "118px",
                  wordBreak: "break-word",
                  opacity: 0,
                  animationName: "fadeIn",
                  animationDuration: "200ms",
                  animationDelay: "1s",
                  animationFillMode: "forwards",
                  textAlign: "right",
                }}
              >
                {year}
              </Text>
            )}
          </Flex>
        </div>
      )}

      {/* 3 */}
      {activeItem === 2 && (
        <div style={{ overflow: "hidden" }}>
          <Text
            color="#fff"
            style={{
              fontFamily: "'Abril Fatface', serif",
              fontSize: "44px",
              lineHeight: "56px",
              letterSpacing: "0.88px",
            }}
          >
            Agárrate fuerte porque tu &lsquo;wrappeo&rsquo; anual vuelve con el
            mismo estilo pero con nuevos datos :)
          </Text>

          {activeItem === 2 && <GifWelcome $image={inicio} />}
        </div>
      )}
    </StepWrapper>
  );
};

export default Welcome;
