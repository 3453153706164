import React, { useEffect, useState } from "react";
import { Flex, Text } from "@sqymagma/elements";

import veterano from "@gifs/dias-veterano.gif";
import medio from "@gifs/dias-medio.gif";
import nuevo from "@gifs/dias-nuevo.gif";

import { googleApi } from "@services";
import { useAuth } from "@contexts";
import moment, { PreciseRangeValueObject } from "moment";
import { StepWrapper } from "../style";
import { WRAPPED_STEPS } from "../constants";
import { GifDays } from "./style";

require("moment-precise-range-plugin");

type DaysProps = {
  active: boolean;
  wrappedDate: Date;
};

const Days = ({ active, wrappedDate }: DaysProps) => {
  const config = WRAPPED_STEPS.find(({ step }) => step === "Days");
  const durations = config?.children;

  const { user } = useAuth();
  const [activeItem, setActiveItem] = useState(0);
  const [days, setDays] = useState(0);
  const [years, setYears] = useState(0);
  const [yearsMonthsDays, setYearsMonthsDays] =
    useState<PreciseRangeValueObject>();

  const nextItem = (active: number) => {
    if (!durations || active >= durations.length) {
      return;
    }

    setActiveItem(active);

    setTimeout(() => {
      nextItem(active + 1);
    }, durations[active] * 1000);
  };

  useEffect(() => {
    if (active && durations) {
      setTimeout(() => {
        nextItem(activeItem + 1);
      }, durations[activeItem] * 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  useEffect(() => {
    const { REACT_APP_PEOPLE_GSHEET_ID: peopleSpreadsheetId } = process.env;
    if (peopleSpreadsheetId) {
      googleApi
        .getSheet({
          spreadsheetId: peopleSpreadsheetId,
          range: "SECUOYAS!A1:ZZ100",
        })
        .then((rows) => {
          if (rows) {
            const headers = rows[0];
            const emailIndex = headers.findIndex((h) => h.trim() === "Email");
            const dateIndex = headers.findIndex(
              (h) => h.trim() === "Fecha incorporación"
            );

            rows.forEach((r) => {
              if (r[emailIndex]?.trim() === user?.email && r[dateIndex]) {
                const date = r[dateIndex].split("/");
                const joiningDate = new Date(
                  `${date[1]}/${date[0]}/${date[2]}`
                );

                const starts = moment(joiningDate);
                const ends = moment(wrappedDate);

                // To calculate the time difference of two dates
                const diffMilliseconds =
                  wrappedDate.getTime() - joiningDate.getTime();

                // To calculate the no. of days between two dates
                const diffDays = Math.round(
                  diffMilliseconds / (1000 * 60 * 60 * 24)
                );

                const preciseDiff = moment.preciseDiff(starts, ends, true);

                setDays(diffDays);
                setYears(preciseDiff.years);
                setYearsMonthsDays(preciseDiff);
              }
            });

            return;
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <StepWrapper
      $bg="#2EAC68"
      $deg1="#fff612"
      $deg2="#4462ff"
      $rotate="144.708deg"
      style={{
        opacity: active ? 1 : 0,
      }}
    >
      {/* 1 */}
      {activeItem === 0 && (
        <div>
          <Text
            color="#FCC167"
            style={{
              fontFamily: "'Abril Fatface', serif",
              fontSize: "56px",
              lineHeight: "60px",
              letterSpacing: "1.12px",
            }}
          >
            Otro año más, otro capítulo más escrito en nuestra historia.
          </Text>
        </div>
      )}

      {/* 2 */}
      {activeItem === 1 && (
        <Flex flexDirection="column" justifyContent="space-between">
          <div>
            <div>
              <Text
                color="#fff"
                style={{
                  fontFamily: "'Noto Serif', serif",
                  fontSize: "27px",
                  lineHeight: "36px",
                  letterSpacing: "0.54px",
                }}
              >
                Llevas con
              </Text>
              <Text
                color="#fff"
                ml="8px"
                style={{
                  fontFamily: "'Abril Fatface', serif",
                  fontSize: "34px",
                  lineHeight: "33px",
                }}
              >
                nosotros
              </Text>
            </div>
            <Text
              color="#FCC167"
              align="right"
              display="block"
              style={{
                fontFamily: "'Abril Fatface', serif",
                fontSize: "176px",
                letterSpacing: "3.52px",
              }}
            >
              {yearsMonthsDays?.years}
            </Text>
            <Text
              color="#FFF4E3"
              align="right"
              display="block"
              style={{
                fontFamily: "'Abril Fatface', serif",
                fontSize: "29px",
                letterSpacing: "0.58px",
              }}
            >
              {yearsMonthsDays?.years === 1 ? "año" : "años"}
            </Text>
            <Text
              color="#FCC167"
              align="right"
              display="block"
              style={{
                fontFamily: "'Abril Fatface', serif",
                fontSize: "120px",
                letterSpacing: "2.4px",
              }}
            >
              {yearsMonthsDays?.months}
            </Text>
            <Text
              color="#FFF4E3"
              align="right"
              display="block"
              style={{
                fontFamily: "'Abril Fatface', serif",
                fontSize: "29px",
                letterSpacing: "0.58px",
              }}
            >
              {yearsMonthsDays?.months === 1 ? "mes" : "meses"}
            </Text>
            <Text
              color="#FCC167"
              align="right"
              display="block"
              style={{
                fontFamily: "'Abril Fatface', serif",
                fontSize: "72px",
                letterSpacing: "1.44px",
              }}
            >
              {yearsMonthsDays?.days}
            </Text>
            <Text
              color="#FFF4E3"
              align="right"
              display="block"
              style={{
                fontFamily: "'Abril Fatface', serif",
                fontSize: "29px",
                letterSpacing: "0.58px",
              }}
            >
              {yearsMonthsDays?.days === 1 ? "día" : "días"}
            </Text>
          </div>
          <div>
            <Text
              color="#fff"
              textStyle="body"
              align="right"
              display="block"
              style={{
                fontSize: "18px",
                letterSpacing: "0.36px",
              }}
            >
              O lo que es lo mismo,
            </Text>
            <Text
              color="#FCC167"
              align="right"
              display="block"
              style={{
                fontFamily: "'Abril Fatface', serif",
                fontSize: "48px",
                lineHeight: "59px",
              }}
            >
              {days.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} días
            </Text>
          </div>
        </Flex>
      )}

      {/* 3 */}
      {activeItem === 2 && (
        <div style={{ overflowY: "hidden", height: "100%" }}>
          {years >= 4 && (
            <>
              <Text
                color="#fff"
                width="80%"
                display="block"
                style={{
                  fontFamily: "'Noto Serif', serif",
                  fontSize: "27px",
                  lineHeight: "36px",
                  letterSpacing: "0.54px",
                }}
              >
                Wow, realmente eres de los
              </Text>
              {activeItem === 2 &&
                ["Ve", "te", "ra", "nos"].map((value, index) => (
                  <Text
                    key={value}
                    color="#FCC167"
                    display="block"
                    style={{
                      fontFamily: "'Abril Fatface', serif",
                      fontSize: "132px",
                      lineHeight: "116px",
                      letterSpacing: "3.84px",
                      opacity: 0,
                      animationName: "fadeIn",
                      animationDuration: "200ms",
                      animationDelay: `${(index + 1) * 500}ms`,
                      animationFillMode: "forwards",
                    }}
                  >
                    {value}
                  </Text>
                ))}

              {activeItem === 2 && (
                <GifDays
                  $image={veterano}
                  $delay={2500}
                  $right="-50px"
                  $bottom="120px"
                />
              )}
            </>
          )}

          {years >= 1 && years < 4 && (
            <>
              <Text
                color="#FCC167"
                display="block"
                style={{
                  fontFamily: "'Abril Fatface', serif",
                  fontSize: "89px",
                  lineHeight: "87px",
                  letterSpacing: "1.78px",
                }}
              >
                Ya son unos cuantos días
              </Text>

              {activeItem === 2 && (
                <GifDays $image={medio} $right="-30px" $bottom="80px" />
              )}
            </>
          )}

          {years < 1 && (
            <>
              <Text
                color="#FCC167"
                display="block"
                style={{
                  fontFamily: "'Abril Fatface', serif",
                  fontSize: "82px",
                  lineHeight: "80px",
                  letterSpacing: "1.64px",
                }}
              >
                Nuestro camino no ha hecho más que empezar
              </Text>

              {activeItem === 2 && (
                <GifDays $image={nuevo} $right="-30px" $bottom="-50px" />
              )}
            </>
          )}
        </div>
      )}
    </StepWrapper>
  );
};

export default Days;
