import { theme } from "@sqymagma/theme";
import { ONE_MINUTE_IN_MS } from "@constants";

export const updateAppTime = 10 * ONE_MINUTE_IN_MS;

export const invalidGoogleAccounts = [
  // Corporate users
  "hola@secuoyas.com",
  "systems@secuoyas.com",
  "cultura@secuoyas.com",
  "systems.ie@secuoyas.com",
  // Non-active users
  "clara.harguindey@secuoyas.com",
];

export const calendarColors = {
  primary: theme("colors.calendar1"),
  secuoyas: theme("colors.calendar2"),
  holidays: theme("colors.holidays"),
  vacations: theme("colors.vacations"),
};

// DATES
export const secuoyasBirthday = "11/09"; // dd/MM
export const christmasRange = ["15/12", "6/1"]; // both days are included
export const wrappedDay = 18;
