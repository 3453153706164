import styled from "styled-components";
import { theme } from "@sqymagma/theme";

const Container = styled.div`
  ${theme("textStyle.body")};
  color: ${(p) => p.theme.colors.text01};
  word-break: break-word;

  p:not(:last-child) {
    margin-bottom: ${(p) => p.theme.spacing.xxs};
  }

  a {
    color: ${(p) => p.theme.colors.link01};
    text-decoration: none;
    &:hover {
      color: ${(p) => p.theme.colors.link01Hover};
    }
  }

  img,
  iframe {
    margin-top: ${(p) => p.theme.spacing.xxxs};
    max-width: 100%;
    border-radius: ${(p) => p.theme.radii.m} !important;
    overflow: hidden;
  }

  img {
    cursor: pointer;
  }
`;

export { Container };
