const capitalizeFirstLetter = (str: string | undefined) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const getInitials = (name = "") => {
  const rgx = /(\p{L}{1})\p{L}+/gu;
  const words = [...name.matchAll(rgx)];
  const initials = words.reduce((previous, current) => {
    return [...previous, current[1]];
  }, [] as Array<string>);
  return initials.join("").slice(0, 2).toUpperCase();
};

const trimText = (text: string | undefined, length: number) => {
  if (!text) return "";
  const sliced = text.slice(0, length);
  return sliced.length < text.length ? `${sliced.trim()}...` : sliced;
};

const getVimeoEmbedUrl = (str: string) => {
  const match = str?.match(
    /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?\/?(.*?(?="|$))/i
  );
  const id = match?.[1];
  const hash = match?.[2];

  let url = `https://player.vimeo.com/video/${id}`;
  url = url.concat("?portrait=false&title=false&byline=false");
  url += hash ? `&h=${hash}` : "";

  return url;
};

const normalize = (str: string) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};

const getUserFromEmail = (str: string) => {
  return `@${str.split("@")[0]}`;
};

function getYouTubeVideoId(url: string) {
  const regex =
    /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
}

function getSpotifyResourceId(url: string) {
  const regex =
    /(?:https?:\/\/open\.spotify\.com\/(?:intl-[a-z]{2}\/)?(track|album|playlist|artist)\/)([a-zA-Z0-9]+)(?:\?.*)?$/;
  const match = url.match(regex);
  return match ? [match[1], match[2]] : [];
}

function getExcerptFromHTML(htmlString: string, length = 0) {
  const element = document.createElement("div");
  element.innerHTML = htmlString;
  element.querySelectorAll("img, iframe").forEach((el) => el.remove());
  const text = element.textContent || element.innerText || "";
  if (length && text.length > length) {
    return text.slice(0, length).trim() + "...";
  }
  return text;
}

export {
  capitalizeFirstLetter,
  getInitials,
  trimText,
  getVimeoEmbedUrl,
  normalize,
  getUserFromEmail,
  getYouTubeVideoId,
  getExcerptFromHTML,
  getSpotifyResourceId,
};
