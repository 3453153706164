import styled, { keyframes } from "styled-components";
import { theme } from "@sqymagma/theme";

import { NAVBAR_WIDTH } from "@components/NavBar/Desktop/constants";

const Lights = styled.div`
  display: none;
  position: absolute;
  z-index: 0;
  left: ${`calc(50% + ${NAVBAR_WIDTH} / 2)`};
  top: 0;
  transform: translate(-50%, -18%);
  width: ${`calc(100% - ${NAVBAR_WIDTH})`};
  min-width: 1200px;
  max-width: 2000px;

  @media (min-width: ${theme("breakpoints.s")}) {
    display: block;
  }
`;

const Snowfall = styled.div`
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
`;

const RunningSanta = keyframes`
0% { transform: translateX(0) scaleX(1)}
42% { transform: translateX(0) scaleX(1)}
50% { transform: translateX(calc(-100vw - 110px)) scaleX(1) }
51% { transform: translateX(calc(-100vw - 110px)) scaleX(-1) }
92% { transform: translateX(calc(-100vw - 110px)) scaleX(-1) }
100% { transform: translateX(0) scaleX(-1) }
`;

const Santa = styled.div`
  display: none;
  position: fixed;
  z-index: 2;
  width: 110px;
  left: 100%;
  bottom: -10px;
  animation: ${RunningSanta} 140s linear infinite;

  @media (min-width: ${theme("breakpoints.s")}) {
    display: block;
  }
`;

export { Lights, Snowfall, Santa };
